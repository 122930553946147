<template>
  <el-dialog
      title="查看"
      v-model="performanceinfo"
      width="50%"
      height="200px"
      center append-to-body @close='closeDialog'>
    <div class="el-dialog-div">
     <el-form   :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" label-height="100px" class="ruleForm">
		
		
		<el-row>
		  <el-form-item label="报修用户" prop="name" >
			<el-input  :readonly="true"  v-model="ruleForm.user_name" ></el-input>
		  </el-form-item>
		  <el-form-item label="真实姓名" prop="name">
			<el-input :readonly="true"  v-model="ruleForm.real_name"></el-input>
		  </el-form-item>
		  <el-form-item label="手机号码" prop="name">
		  					<el-input  :readonly="true"  v-model="ruleForm.phone"></el-input>
		  </el-form-item>
		  </el-row>
		  
		  <el-row>

		  <el-form-item label="所属项目" prop="name">
					<el-input :readonly="true"  v-model="ruleForm.plot_name"></el-input>
		  </el-form-item>
		  <el-form-item label="报修来源" prop="name">
			<el-input  :readonly="true"  v-model="ruleForm.is_substitute"></el-input>
		  </el-form-item>
		  <el-form-item label="报修地点" prop="name">
			<el-input  :readonly="true"  v-model="ruleForm.address"></el-input>
		  </el-form-item>
		  <!-- <el-form-item label="报修地点-楼" prop="name">
			<el-input  :readonly="true"  v-model="ruleForm.floor"></el-input>
		  </el-form-item>
		  <el-form-item label="报修地点-层" prop="name">
			<el-input  :readonly="true"  v-model="ruleForm.layer"></el-input>
		  </el-form-item>
		  <el-form-item label="报修区域" prop="name">
			<el-input  :readonly="true"  v-model="ruleForm.region"></el-input>
		  </el-form-item> -->
		  </el-row>
		   <el-row>
      <el-form-item label="报修分类" prop="name">
        <el-input :readonly="true"   v-model="ruleForm.father_name"></el-input>
      </el-form-item>
      <el-form-item label="报修模式" prop="name">
        <el-input :readonly="true"  v-model="ruleForm.mode"></el-input>
      </el-form-item>
      <el-form-item label="工单类型" prop="name">
        <el-input :readonly="true"   v-model="ruleForm.order_type" ></el-input>
      </el-form-item>
      <el-form-item label="报修金额" prop="name">
        <el-input  :readonly="true"  v-model="ruleForm.money"></el-input>
      </el-form-item>
	  <el-form-item label="报修时间" prop="name">
	    <el-input  :readonly="true"  v-model="ruleForm.create_time"></el-input>
	  </el-form-item>
	  <el-form-item label="抢单时间" prop="name">
	    <el-input  :readonly="true"  v-model="ruleForm.hand_time"></el-input>
	  </el-form-item>
	  </el-row>
	  
	    <el-row>
      <el-form-item label="详细信息" prop="name">
        <el-input  :readonly="true"  v-model="ruleForm.content"></el-input>
      </el-form-item>
      <el-form-item label="评价星数" prop="name">
        <el-input :readonly="true"  v-model="ruleForm.stars_nums"></el-input>
      </el-form-item>
      <el-form-item label="完成时间" prop="name">
        <el-input :readonly="true"  v-model="ruleForm.finish_time"></el-input>
      </el-form-item>
	  
	  <el-form-item label="评价内容" prop="name">
	    <el-input :readonly="true"   type="textarea" v-model="ruleForm.comment"></el-input>
	  </el-form-item>
	  <el-form-item label="抢单超时批注" prop="name" v-show="ruleForm.grab_annotation != undefined">
	    <el-input :readonly="true"   type="textarea" v-model="ruleForm.grab_annotation"></el-input>
	  </el-form-item>
	  <el-form-item label="维修超时批注" prop="name" v-show="ruleForm.repair_annotation != undefined">
	    <el-input :readonly="true"   type="textarea" v-model="ruleForm.repair_annotation"></el-input>
	  </el-form-item>
	  
	   </el-row>
	   
	   <el-row v-show="ruleForm.status == '5'">
	   <el-form-item label="返修人" prop="name">
	     <el-input  :readonly="true"  v-model="ruleForm.fix_user_name"></el-input>
	   </el-form-item>
	   <el-form-item label="返修时间" prop="name">
	     <el-input :readonly="true"  v-model="ruleForm.fix_time"></el-input>
	   </el-form-item>
	   <el-form-item label="返修原因" prop="name">
	     <el-input :readonly="true"  v-model="ruleForm.fix_reason"></el-input>
	   </el-form-item>
	   
	    </el-row>
	  
	  <el-row>
     <!-- <el-form-item label="图片地址" prop="name">
        <el-input  :readonly="true" v-model="ruleForm.picture"></el-input>
      </el-form-item> -->
      <el-form-item label="维修人员" prop="name">
        <el-input :readonly="true" v-model="ruleForm.repair_username"></el-input>
      </el-form-item>
	  <el-form-item label="备注" prop="name">
	    <el-input :readonly="true"   type="textarea" v-model="ruleForm.remark"></el-input>
	  </el-form-item>
	    </el-row>
     
	 <el-form-item label="结单说明" prop="name">
	   <el-input :readonly="true"   type="textarea" v-model="ruleForm.reason"></el-input>
	 </el-form-item>
	 
	   <el-row>
      <el-form-item label="报修图片" prop="name">
		<el-image
		  border="11"
		  style="margin: 3px;  padding: 3px; width:100px;height:100px;border:solid 1px #6a7990; border-radius:10%"
		  v-for="item in srcListFirst"
		  v-bind:key="item"
		  hide-on-click-modal="true"
		  :src="item" 
		  :preview-src-list="srcListFirst">
		</el-image>
	  </el-form-item>
      <el-form-item label="维修图片" prop="name">
		<el-image
		  border="11"
		  style="margin: 3px;  padding: 3px; width:100px;height:100px;border:solid 1px #6a7990; border-radius:10%"
		  v-for="item in srcList"
		  v-bind:key="item"
		  hide-on-click-modal="true"
		  :src="item" 
		  :preview-src-list="srcList">
		</el-image>
	  </el-form-item>
	 
	  
	  <el-form-item label="电子签名" prop="name" v-show="statementPicture.length > 0">
	  		<el-image
	  		  border="11"
	  		  style="margin: 3px;  padding: 3px; width:100px;height:100px;border:solid 1px #6a7990; border-radius:10%"
	  		  v-for="item in statementPicture"
	  		  v-bind:key="item"
	  		  hide-on-click-modal="true"
	  		  :src="item" 
	  		  :preview-src-list="statementPicture">
	  		</el-image>
	  </el-form-item>
	   </el-row>
	   
	   
	   
	    <el-row>
			<el-form-item label="用料表" prop="name">
			  <el-table :data="dataList" row-key="id" border  ref="multipleTable">
			    <el-table-column label="序号" width="50">
			  		<template v-slot="scope">
			  			{{ scope.$index+1 }}
			  		</template>
			  	</el-table-column>
			  	<el-table-column prop="plot_name" label="所属项目" width="150"></el-table-column>
			    <el-table-column prop="typeName" label="一级分类"   width="100"></el-table-column>
			    <el-table-column prop="secName" label="二级分类"  width="100"></el-table-column>
			    <el-table-column prop="quantity" label="数量"  width="50" ></el-table-column>
			  	<el-table-column prop="user_name" label="使用人"></el-table-column>
				<el-table-column prop="remark" label="备注"></el-table-column>
			  	<el-table-column prop="datetime" label="时间" value-format="YYYY-MM-DD"  width="120"></el-table-column>
			    <!-- <el-table-column fixed="right" label="操作" width="100" >
			      <template v-slot="scope">
			        <el-button  icon="el-icon-edit"    @click="View(scope.row)" type="success" size="small">查看</el-button>
			      </template>
			    </el-table-column> -->
			  </el-table>
			</el-form-item>
	   </el-row>
	   
	  <div v-if="isShow">
	  	  <el-divider content-position="center" style="background-color: red;">请评价</el-divider>
	  	</div>
	  
	  	  <el-form-item label="评价星数" prop="comment" v-if="isShow">
	  		<el-rate
	  		  v-model="stars_nums"
	  		/>
	  	  </el-form-item>
	  	  
	  	  <el-form-item label="评价内容" prop="comment" v-if="isShow">
	  	    <el-input  type="textarea" v-model="comment"></el-input>
	  	  </el-form-item>
	  	  
	  	  <el-form-item v-if="isShow">
	  			<el-button type="danger" plain v-on:click="dangerSubmit">提交</el-button>
	  	  </el-form-item>
		  
    </el-form>
    <div class="clear"></div>
    </div>
    <div class="ucForm-group-ad">
      <div class="ucForm-group ucBtn-bg">
        <button class="ucBtn ucBtn-refresh ucBtn-gray" v-on:click="closeDialog" ><i
            class="iImg"></i>返回</button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  created () {
    this.performanceinfo = true;
    this.clearinfo();
    this.handleClickQuery();
	this.getDatas();
  },
  data(){
    let self=this;
    return{
      position:'top',
	  comment:'',
	  stars_nums:'',
	  isShow:false,
      ruleForm:{
        id:this.$parent.id,
      },
	  srcList:[],
	  srcListFirst:[],
	  statementPicture:[],
	  totalCount:0,
	  dataList:[],
    }},
  methods: {
	   dangerSubmit(){
	  	  	var self = this;
	  	  	var data = {}
	  	  	data.cmd = "updateRepair"
	  	  	data.id = this.$parent.id;
	  	  	data.comment = this.comment;
	  	  	data.stars_nums = this.stars_nums;
	  	    this.$sknet.postJson(this.$skconf("cla.surl"), data, function(res) {
	  			if (res.result == 'OK') {
	  				this.closeDialog();
	  			}
	  	    }, null, this);
	  	  },
    handleClickQuery:function(data){
      this.dataList = [];
      var req = {};
      req.cmd = "searchRepairs";
      req.id=this.$parent.id;
      this.$sknet.postJson(this.$skconf("cla.surl"), req, function(res) {
        this.ruleForm=res.datas.items[0];
        var picture=this.ruleForm.picture;
        var maintain=this.ruleForm.maintain_url;
		if(this.ruleForm.stars_nums  == "" || this.ruleForm.stars_nums  == null){
			this.isShow = this.ruleForm.openid == null || this.ruleForm.openid == undefined;
		}
		var statePicture = this.ruleForm.statementPicture;
		if(statePicture != null){
		  var picturearray = statePicture.split(',')
		  for(let i=0;picturearray.length>i;i++){
			  this.statementPicture.push(picturearray[i]);
		  }
		}
		
        if(picture!=null){
          var picturearray=picture.split(',')
          for(let i=0;picturearray.length>i;i++){
			  this.srcListFirst.push(picturearray[i]);
          }
        }
        if(maintain!=null){
          var maintainarray=maintain.split(',')
          for(let i=0;maintainarray.length>i;i++){
            if(maintainarray[i]!=''&&picturearray!=null) {
			  this.srcList.push(maintainarray[i]);
            }
          }
        }
        
		if(this.ruleForm.mode==1){
		    this.ruleForm.mode='有偿';
		}else if(this.ruleForm.mode==2){
		  this.ruleForm.mode='无偿';
		}
		
		if(this.ruleForm.is_substitute == 1){
		    this.ruleForm.is_substitute='后台录入';
		}else{
		    this.ruleForm.is_substitute='app录入';
		}
		
        if(this.ruleForm.order_type==1){
          this.ruleForm.order_type='等待抢单';
        }else if(this.ruleForm.order_type==2){
          this.ruleForm.order_type='前台抢单';
        }else if(this.ruleForm.order_type==3){
          this.ruleForm.order_type='后台分配';
        }else if(this.ruleForm.order_type==4){
          this.ruleForm.order_type='其他单位';
        }
       }, null, this);
    },
    closeDialog: function(){
      this.clearinfo();
      this.$emit('closerepairsView'); //通知父组件改变。
    },
    clearinfo:function(){
    },
	getDatas:function(){
	  this.dataList = [];
	  var req = {};
	  req.order_id = this.$parent.id;;
	  req.cmd = "searchMaterials";
	  req.start=0;
	  req.count=100;
	  this.$sknet.postJson(this.$skconf("met.surl"), req, function(res) {
	    console.log(res);
	    this.dataList=res.datas.items;
	    this.totalCount=res.datas.totalCount;
	  }, null, this);
	},
  },
  mounted(){
  },
  components: {
  }
}
</script>
<style scoped>
.el-dialog-div {
  height: 60vh;
  overflow: auto;
  Margin:5px;
}
</style>